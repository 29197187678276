<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link v-if="type_user[1] != 'DGIR'"
                     :to="'/decompte'">
          Décompte >
        </router-link>
        <router-link v-else
                     :to="'/decompte'">
          Facture >
        </router-link>
        <span v-if="type_user[1] != 'DGIR'">Création de décompte de prestation intellectuelle</span>
        <span v-else>Création de facture de prestation intellectuelle</span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title"
               v-if="type_user[1] != 'DGIR'">
            Création de décompte de prestation intellectuelle
          </div>
          <div v-else 
               class="card-body text-center title">
            Création de factures de prestation intellectuelle 
          </div>
        </div>
      </div>
    </div>
    <div class="inner_container">
      <div class="col-md-11 mx-auto">
        <div class="row">
          <Notif :notif="notif" />
        </div>
        <form class="accordion" 
              id="accordionExample">
          <table class="table col-md-8 mx-auto"
                 style="font-size: 0.6em;">
            <thead class="card-header"
                   id="headingOne">
              <tr class="bg-black"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                <th>Source du décompte</th>
              </tr>
            </thead>
            <div id="collapseOne"
                 class="collapse show"
                 aria-labelledby="headingOne"
                 data-parent="#accordionExample">
              <div class="mt-3">
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Numéro de marché <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select :options="marches.donnees"
                              :reduce="marche => marche.id"
                              label="numero_marche"
                              ref="marche"
                              class="v-select"
                              @input="searchMarche(form.marche)"
                              v-model="form.marche"
                              placeholder="Choisir un marché">
                      <span slot="no-options"
                            @click="$refs.marche.open = false"
                            class="text-danger">
                        Aucun marché trouvé
                      </span>
                    </v-select>
                    <span v-if="form.errors().has('marche') && affiche.marche !== 'marche'"
                          v-text="form.errors().get('marche')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <template v-if="marche.activite">
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Ordonnancement <sup><span class="req-star">*</span></sup>
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <v-select :options="marche.ordonnancements"
                                :reduce="ordonnancement =>ordonnancement.id"
                                label="annee_ordonnancement"
                                ref="marche"
                                class="v-select"
                                @input="searchSituation(form.marche,form.ordonnancement)"
                                v-model="form.ordonnancement"
                                placeholder="Choisir un ordonnancement">
                        <span slot="no-options"
                              @click="$refs.marche.open = false"
                              class="text-danger">
                          Aucun ordonnancement trouvé
                        </span>
                      </v-select>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Programme</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.activite.rubrique.programme.libelle }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Rubrique</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.activite.rubrique.libelle }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Activité</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.activite.libelle }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Type d'entretien</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.type_entretien }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Département</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <span v-for="(departement,dekey) in marche.departement"
                            :key="dekey">{{ departement.libelle }}</span> <br>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Objet des travaux</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.objet }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date d'approbation</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_approbation_marche }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Lot</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.lot }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Entreprise</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.entreprise.libelle }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Référence ordre de service de démarrage</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.reference_ordre_service_demarrage }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date ordre de service de demarrage</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_ordre_service_demarrage }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date de début</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_debut }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date de fin</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_fin }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Délai d'exécution (mois)</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.delai_execution_marche }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date démarrage effectif</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_demarrage_effectif }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date de fin des travaux</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_fin_travaux }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Référence ordre de service d'arrêt</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.reference_ordre_service_arret }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date ordre de service d'arrêt</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_ordre_service_arret }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Référence ordre de service de reprise des travaux</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.reference_ordre_service_reprise_travaux }}</div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date ordre de service de reprise des travaux</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">{{ marche.date_ordre_service_reprise_travaux }}</div>
                  </div>
                </template>
              
              </div>
            </div>
          </table>

          <table class="table col-md-8 mx-auto"
                 style="font-size: 0.6em;">
            <thead class="card-header"
                   id="headingTwo">
              <tr class="bg-black"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo">
                <th>Information du décompte</th>
              </tr>
            </thead>
            <div id="collapseTwo"
                 class="collapse"
                 aria-labelledby="headingTwo"
                 data-parent="#accordionExample">
              <div class="mt-3">
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Situation financière du marché</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    {{ form.situation_financiere_marche.toLocaleString() }} FCFA
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Situation financière de l'ordonnancement</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    {{ form.situation_financiere_ordonnancement.toLocaleString() }} FCFA
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Numéro décompte</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           v-on:click="removeSpanError('numero_decompte')"
                           v-model="form.numero_decompte" />
                    <span v-if="form.errors().has('numero_decompte') && affiche.numero_decompte !== 'numero_decompte'"
                          v-text="form.errors().get('numero_decompte')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Montant Décompte</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           @input="addSpace('montant_facture_decompte')"
                           v-on:click="removeSpanError('montant_facture_decompte')"
                           v-model="montant_facture_decompte" />
                    <span
                      v-if="form.errors().has('montant_facture_decompte') && affiche.montant_facture_decompte !== 'montant_facture_decompte'"
                      v-text="form.errors().get('montant_facture_decompte')"
                      class="errorMsg"
                    >
                    </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Date etablissement du décompte</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-on:click="removeSpanError('date_facture')"
                           v-model="form.date_facture" />
                    <span v-if="form.errors().has('date_facture') && affiche.date_facture !== 'date_facture'"
                          v-text="form.errors().get('date_facture')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Référence Ordre Service de modification d'itinéraire</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           v-model="form.reference_ordre_service_modification_itineraire" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date de l'Ordre Service de modification d'itinéraire</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-model="form.date_service_modification_itineraire" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Référence Ordre service de prorogation de delai</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           v-model="form.reference_ordre_service_prorogation_delai" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date de l'Ordre service de prorogation de delai</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-model="form.date_ordre_service_prorogation_delai" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Référence Facture</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           v-on:click="removeSpanError('reference_facture')"
                           v-model="form.reference_facture" />
                    <span v-if="form.errors().has('reference_facture') && affiche.reference_facture !== 'reference_facture'"
                          v-text="form.errors().get('reference_facture')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Montant Facture</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           @input="addSpace('montant_decompte')"
                           v-on:click="removeSpanError('montant_decompte')"
                           v-model="montant_decompte" />
                    <span
                      v-if="form.errors().has('montant_decompte') && affiche.montant_decompte !== 'montant_decompte'"
                      v-text="form.errors().get('montant_decompte')"
                      class="errorMsg"
                    >
                    </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Date d'établissement de la facture</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-model="form.date_etablissement_decompte" />
                  </div>
                </div>

                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Taux d'exécution physique</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    
                    <input type="text"
                           class="form-control"
                           v-model="form.taux_execution_physique" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Date d'établissement du certificat de service fait</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-on:click="removeSpanError('date_certificat_service_fait')"
                           v-model="form.date_certificat_service_fait"/>
                    <span v-if="form.errors().has('date_certificat_service_fait') && affiche.date_certificat_service_fait !== 'date_certificat_service_fait'"
                          v-text="form.errors().get('date_certificat_service_fait')"
                          class="errorMsg"></span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Date d'établissement de la feuille de temps</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-on:click="removeSpanError('date_etablissement_feuille_temps')"
                           v-model="form.date_etablissement_feuille_temps"/>
                    <span v-if="form.errors().has('date_etablissement_feuille_temps') && affiche.date_etablissement_feuille_temps !== 'date_etablissement_feuille_temps'"
                          v-text="form.errors().get('date_etablissement_feuille_temps')"
                          class="errorMsg"></span>
                  </div>
                </div>
                
                
                
                
              </div>
            </div>
          </table>
          <table class="table col-md-8 mx-auto"
                 style="font-size: 0.6em;">
            <thead class="card-header"
                   id="headingFour">
              <tr class="bg-black"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour">
                <th>Rapports</th>
              </tr>
            </thead>
            <div id="collapseFour"
                 class="collapse"
                 aria-labelledby="headingFour"
                 data-parent="#accordionExample">
              <div class="mt-3">
                <div class="col-md-12"
                     v-for="(item, index) in rapports"
                     :key="index">
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label class="">Rapport n°{{ index }}</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <div class="custom-file">
                        <label class="custom-file-label">
                          {{ item.rapport }}
                        </label>
                        <input
                          type="file"
                          :name="'rapport'+index"
                          class="custom-file-input"
                          :id="'rapport'+index"
                          :ref="'rapport'+index"
                          v-on:change="handleRapportUpload(index)"
                          accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                          :disabled="disabled"
                        />
                        <span v-if="form.errors().has('ordre_service_prorogation_delai') && affiche.ordre_service_prorogation_delai !== 'ordre_service_prorogation_delai'"
                              v-text="form.errors().get('ordre_service_prorogation_delai')"
                              class="errorMsg"> </span><br />
                        <span class="listExtension"> Format :
                          <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                          </span>
                        </span>
                      </div>
                    </div>
                    <span class="errorMsg"> </span>
                    <div class="form-group col-md-1 pt-2 my-auto">
                      <Loader v-show="loading.ordre_service_prorogation_delai" />
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Date du rapport
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-model="form.rapports[index].date_rapport"
                             :name="`data[${index}]date_rapport`"/>
                    </div>
                  </div>
                  
                </div>
                <div class="row mb-3">
                  <button type="button"
                          class="btn btn-f-blue"
                          v-on:click="rapports.push({rapport:'',date_rapport:''}),form.rapports.push({rapport:'',date_rapport:''})">+</button>
                  <button type="button" 
                          class="btn btn-f-blue"
                          v-on:click="rapports.splice(rapports.length-1, 1),form.rapports.splice(form.rapports.length-1, 1)">-</button>
                </div>
                
              </div>
            </div>
          </table>
          <!--  -->
          <table class="table col-md-8 mx-auto"
                 style="font-size: 0.6em;">
            <thead class="card-header"
                   id="headingThree">
              <tr class="bg-black"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree">
                <th>Fichiers à télécharger</th>
              </tr>
            </thead>
            <div id="collapseThree"
                 class="collapse"
                 aria-labelledby="headingThree"
                 data-parent="#accordionExample">
              <div class="mt-3">
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Feuille de temps</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.feuille_temps }}
                      </label>
                      <input
                        type="file"
                        name="feuille_temps"
                        class="custom-file-input"
                        id="feuille_temps"
                        ref="feuille_temps"
                        v-on:click="removeSpanError('feuille_temps')"
                        v-on:change="handleFileUpload('feuille_temps')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span
                        v-if="form.errors().has('feuille_temps') && affiche.feuille_temps !== 'feuille_temps'"
                        v-text="form.errors().get('feuille_temps')"
                        class="errorMsg"
                      >
                      </span>
                      <br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                    <span class="errorMsg"> </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.feuille_temps" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Décompte</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.decompte }}
                      </label>
                      <input
                        type="file"
                        name="decompte"
                        class="custom-file-input"
                        id="decompte"
                        ref="decompte"
                        v-on:click="removeSpanError('decompte')"
                        v-on:change="handleFileUpload('decompte')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span v-if="form.errors().has('decompte') && affiche.decompte !== 'decompte'"
                            v-text="form.errors().get('decompte')"
                            class="errorMsg"> </span><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                    <span class="errorMsg"> </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.decompte" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Facture</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.facture }}
                      </label>
                      <input
                        type="file"
                        name="facture"
                        class="custom-file-input"
                        id="facture"
                        ref="facture"
                        v-on:click="removeSpanError('facture')"
                        v-on:change="handleFileUpload('facture')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span v-if="form.errors().has('facture') && affiche.facture !== 'facture'"
                            v-text="form.errors().get('facture')"
                            class="errorMsg"> </span><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <span class="errorMsg"> </span>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.facture" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Ordre de service de prorogation de délai</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.ordre_service_prorogation_delai }}
                      </label>
                      <input
                        type="file"
                        name="ordre_service_prorogation_delai"
                        class="custom-file-input"
                        id="ordre_service_prorogation_delai"
                        ref="ordre_service_prorogation_delai"
                        v-on:click="removeSpanError('ordre_service_prorogation_delai')"
                        v-on:change="handleFileUpload('ordre_service_prorogation_delai')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span v-if="form.errors().has('ordre_service_prorogation_delai') && affiche.ordre_service_prorogation_delai !== 'ordre_service_prorogation_delai'"
                            v-text="form.errors().get('ordre_service_prorogation_delai')"
                            class="errorMsg"> </span><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <span class="errorMsg"> </span>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.ordre_service_prorogation_delai" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Ordre de service de modification d'itinéraire</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.ordre_service_modification_itineraire }}
                      </label>
                      <input
                        type="file"
                        name="ordre_service_modification_itineraire"
                        class="custom-file-input"
                        id="ordre_service_modification_itineraire"
                        ref="ordre_service_modification_itineraire"
                        v-on:click="removeSpanError('ordre_service_modification_itineraire')"
                        v-on:change="handleFileUpload('ordre_service_modification_itineraire')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span v-if="form.errors().has('ordre_service_modification_itineraire') && affiche.ordre_service_modification_itineraire !== 'ordre_service_modification_itineraire'"
                            v-text="form.errors().get('ordre_service_modification_itineraire')"
                            class="errorMsg"> </span><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <span class="errorMsg"> </span>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.ordre_service_modification_itineraire" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Certificat de service fait</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.certificat_service_fait }}
                      </label>
                      <input
                        type="file"
                        name="certificat_service_fait"
                        class="custom-file-input"
                        id="certificat_service_fait"
                        ref="certificat_service_fait"
                        v-on:click="removeSpanError('certificat_service_fait')"
                        v-on:change="handleFileUpload('certificat_service_fait')"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span v-if="form.errors().has('certificat_service_fait') && affiche.certificat_service_fait !== 'certificat_service_fait'"
                            v-text="form.errors().get('certificat_service_fait')"
                            class="errorMsg"> </span><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <span class="errorMsg"> </span>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.certificat_service_fait" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Observations</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <textarea class="form-control"
                              rows="3"
                              v-on:click="removeSpanError('observation')"
                              v-model="form.observation"> </textarea>
                    <span v-if="form.errors().has('observation') && affiche.observation !== 'observation'"
                          v-text="form.errors().get('observation')"
                          class="errorMsg"> </span>
                  </div>
                </div>
              </div>
            </div>
          </table>
          <div class="form-row row mt-4">
            <div class="col-md-8 offset-sm-5">
              <div class="form-group">
                <div class="button_form">
                  <button type="button"
                          class="btn btn-f-blue"
                          @click="submit">
                    <i class="flaticon flaticon-floppy-disk"></i>
                    Envoyer
                  </button>
                  <!-- <button type="button"
                    class="btn btn-f-blue"
                    @click="saving()">
              <i class="flaticon flaticon-floppy-disk"></i>
              Enregistrer
            </button> -->
                  <button type="reset"
                          class="btn btn-f-blue"
                          data-toggle="modal"
                          data-target="#exampleModal">
                    <i class="flaticon flaticon-cancel"></i>
                    Annuler
                  </button>
                </div>
              </div>
            </div>
          </div>
         
        </form>

        <!-- modal box -->
        <div class="modal fade"
             id="exampleModal"
             tabindex="-1"
             role="dialog"
             aria-labelledby="exampleModalLabel"
             aria-hidden="true">
          <div class="modal-dialog"
               role="document">
            <div class="modal-content">
              <div class="modal-header ">
                <h5 class="modal-title text-uppercase"
                    id="exampleModalLabel">Annulation</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Cette action est irréversible, êtes-vous sure de vouloir annuler ?</p>
              </div>
              <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal">Non</button>
                <button type="button"
                        @click="goBack()"
                        data-dismiss="modal"
                        class="btn btn-primary">Oui</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import "./decompte.css";
.listExtension {
  font-size: 0.8em;
}
div ::placeholder {
  color: rgba(220, 220, 220, 0.897);
}
.fileExtentions {
  color: #0d6efd;
}
form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea.form-control,
form select.form-control,
form .btn-f-blue,
span[slot="no-options"].text-danger{
  font-size: 0.8em;
}
form select.form-control option{
  font-size: 1em;
}
input.vs__search{
  font-size: 0.7em;
}
form.form_default input[type="text"], 
form.form_default input[type="date"], 
form.form_default input[type="number"], 
form.form_default input[type="file"], 
form.form_default textarea{
  height: fit-content;
}
/* div.vs__dropdown-toggle,form select.form-control{
  height: 3.5vh;
} */
@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: fit-content;
  }
}
</style>
<script>
import Loader from "@/components/shared/Spinner"
import Notif from "@/components/shared/Toast"
import form from "vuejs-form"
import { mapActions, mapGetters, mapMutations } from "vuex"
import utils from "@/assets/js/utils"
import VSelect from "vue-select"
import "vue-select/dist/vue-select.css"
export default {
  name: "CreateDecompte",
  components: {
    Loader,
    Notif,
    VSelect
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    type_user: "",
    montant_ordonnancement: "",
    montant_total_contrat: "",
    montant_facture_decompte: "",
    rapports:[{
      rapport:"",
      date_rapport:""
    }],
    form: form({
      numero_decompte: "",
      type_decompte: "",
      objet_travaux: "",
      numero_contrat: "",
      marche: "",
      montant_total_contrat: "",
      decompte: "",
      certificat_service_fait: "",
      facture: "",
      feuille_temps:"",
      montant_facture_decompte: "",
      reference_facture: "",
      date_facture: "",
      observation: "",
      situation_financiere_marche: "",
      situation_financiere_ordonnancement: "",
      date_ordre_service_prorogation_delai:"",
      date_service_modification_itineraire:"",
      date_etablissement_decompte:"",
      taux_execution_physique:"",
      montant_decompte:"",
      reference_ordre_service_prorogation_delai: "",
      reference_ordre_service_modification_itineraire: "",
      date_etablissement_feuille_temps: "",
      date_etablissement_certificat_service_fait: "",
      ordre_service_prorogation_delai: "",
      ordre_service_modification_itineraire: "",
      rapports:[{rapport:"",date_rapport:""}],
      ordonnancement:""
    })
      .rules({
        numero_decompte: "required",
        type_decompte: "required",
        marche: "required",
        montant_facture_decompte: "required",
        reference_facture: "required",
        date_facture: "required",
        date_etablissement_decompte:"required",
        montant_decompte:"required",
      })
      .messages({
        numero_decompte: "Ce champs est requis",
        type_decompte: "Ce champs est requis",
        marche: "Ce champs est requis",
        montant_facture_decompte: "Ce champs est requis",
        reference_facture: "Ce champs est requis",
        date_facture: "Ce champs est requis",
        date_etablissement_decompte:"Ce champs est requis",
        montant_decompte:"Ce champs est requis",
      }),
    marcheData: {
      limite: 100,
      avant: "MQ=="
    },
    gmarche: "",
    uploadfile: "courrier_indication_ligne_lcd",
    disabled: false,
    loading: {
      decompte: false,
      certificat_service_fait: false,
      facture: false,
      feuille_temps:false,
      ordre_service_prorogation_delai: false,
      ordre_service_modification_itineraire: false,
    },
    inputFiles: {
      decompte: "",
      certificat_service_fait: "",
      facture: "",
      feuille_temps:"",
      ordre_service_prorogation_delai: "",
      ordre_service_modification_itineraire: "",
    },
    listExtensionFiles: [],
    affiche: {
      numero_decompte: "numero_decompte:true",
      type_decompte: "type_decompte:true",
      objet_travaux: "objet_travaux:true",
      numero_contrat: "numero_contrat:true",
      marche: "marche:true",
      montant_total_contrat: "montant_total_contrat:true",
      decompte: "decompte:true",
      certificat_service_fait: "certifcat_service_fait:true",
      facture: "facture:true",
      feuille_temps:"feuille_temps:true",
      montant_facture_decompte: "montant_facture_decompte:true",
      reference_facture: "reference_facture:true",
      date_facture: "date_facture:true",
      observation: "observation:true",
      date_ordre_service_prorogation_delai:"date_ordre_service_prorogation_delai:true",
      date_service_modification_itineraire:"date_service_modification_itineraire:true",
      date_etablissement_decompte:"date_etablissement_decompte:true",
      taux_execution_physique:"taux_execution_physique:true",
      montant_decompte:"montant_decompte:true",
      reference_ordre_service_prorogation_delai:"reference_ordre_service_prorogation_delai:true",
      reference_ordre_service_modification_itineraire:"reference_ordre_service_modification_itineraire:true",
      date_etablissement_feuille_temps:"date_etablissement_feuille_temps:true",
      date_etablissement_certificat_service_fait:"date_etablissement_certificat_service_fait:true",
      ordre_service_prorogation_delai:"ordre_service_prorogation_delai:true",
      ordre_service_modification_itineraire:"ordre_service_modification_itineraire:true",
    },

    //Ces deux variables sont pouvoir referencer les attributs des dates
    date_debut: "date_debut_execution",
    date_fin: "date_fin_execution",
    montant_decompte:"",
    //La liste des decomptes deja utilisé
    listNumeroDecompte: []
  }),
  watch: {
    files() {
      if (this.files && this.files["url"]) {

        if (this.uploadfile.length > 2) {
          this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
          this.loading[this.uploadfile] = false
          this.disabled = false
        }else{
          this.form.rapports[this.uploadfile].rapport = process.env.VUE_APP_UPLOAD + this.files["url"]
          this.loading[this.uploadfile] = false
          this.disabled = false
        }
      }
    },
    gmarche() {
      if (this.gmarche) {
        this.form.reference_attestation_rc = this.gmarche.reference_arc
        this.form.reference_accord_financement = this.gmarche.reference_af
      }
    },
    filerrors() {
      if (this.filerrors) {
        this.loading[this.uploadfile] = false
        this.notif.message = this.filerrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.disabled = false
            this.notif.activated = ""
            this.$refs[this.uploadfile]=""
            this.form[this.uploadfile]=""
            this.inputFiles[this.uploadfile]=""
            this.setFileErors("")
          }.bind(this),
          3000
        )
      }
    },
    situationMarche(){
      if (this.situationMarche) {
        this.form.situation_financiere_marche = Number(this.situationMarche.situation_financiere[0].marche)
        this.form.situation_financiere_ordonnancement = Number(this.situationMarche.situation_financiere[0].ordonnancement)
        console.log(this.situationMarche)
      }
    },
    successdecompteP() {
      if (this.successdecompteP) {
        this.notif.message = this.successdecompteP
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setSuccess("")
            this.$router.push({ name: "decompte" })
          }.bind(this),
          3000
        )
      }
      if (this.decompterrorsP) {
        this.notif.message = this.decompterrorsP
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }
    },
    successdecompte() {
      if (this.successdecompte) {
        this.notif.message = this.successdecompte
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.$router.push({ name: "decompte" })
          }.bind(this),
          3000
        )
      }
      if (this.decompterrors) {
        this.notif.message = this.decompterrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    }
  },
  created() {
    this.ListMarches()
    this.listExtensionFiles = utils.formaFichierAccepte
    this.getSousTraitants()
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.type_user = userdetails.user_role.split("_")
    //Recuperation de la liste des numero de decompte deja utilisé
    if (this.decomptes.donnees) {
      this.decomptes.donnees.forEach(decompte => {
        this.listNumeroDecompte.push(decompte.numero_decompte)
      })
    }
  },
  computed: {
    ...mapGetters(["marches","marche", "errors", "successdecompte", "successdecompteP", "decompterrorsP", "filerrors", "soustraitants", "files", "decompterrors", "decomptes","situationMarche","msgSituationMarche"])
  },
  methods: {
    ...mapActions(["groupMarches", "saveDP", "saveDecomptes", "getSousTraitants", "ListMarches", "saveFile", "Decomptes","situationMarcheDecompte","getMarche"]),
    ...mapMutations(["setSituationMarche","setFileErors","setMsgFailSituationMarche"]),
    handleFileUpload(filename) {
      this.inputFiles[filename] = this.$refs[filename].files[0].name
      if (!this.inputFiles[filename].includes(" ")) {
        const extension = this.inputFiles[filename].split(".")[1]
        if (this.listExtensionFiles.includes(extension)) {
          this.loading[filename] = true
          this.disabled = true
          this.uploadfile = filename
          this.saveFile(this.$refs[filename].files[0])
        } else {
          this.inputFiles[filename] = ""
          this.notif.message = "Mauvais format du fichier !!"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          setTimeout(
            function() {
              this.notif.activated = ""
            }.bind(this),
            5000
          )
        }
      }else{
        this.inputFiles[filename] = ""
        this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    },
    handleRapportUpload(filename) {
      this.rapports[filename].rapport = this.$refs["rapport"+filename][0].files[0].name
      console.log(this.rapports[filename].rapport)
      if (!this.rapports[filename].rapport.includes(" ")) {
        const extension = this.rapports[filename].rapport.split(".")[1]
        if (this.listExtensionFiles.includes(extension)) {
          this.loading[filename] = true
          this.disabled = true
          this.uploadfile = filename
          this.saveFile(this.$refs["rapport"+filename][0].files[0])
        } else {
          this.rapports[filename].rapport = ""
          this.notif.message = "Mauvais format du fichier !!"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          setTimeout(
            function() {
              this.notif.activated = ""
            }.bind(this),
            5000
          )
        }
      }else{
        this.rapports[filename].rapport = ""
        this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    },
    // getMarche(id) {
    //   this.setSituationMarche("")
    //   this.situationMarcheDecompte(id)
    //   // this.removeSpanError('marche')
    // },
    searchMarche(id) {
      this.getMarche(id)
      this.removeSpanError('marche')
    },
    searchSituation(id_marche,id_ordonnancement) {
      this.setSituationMarche("")
      this.form.situation_financiere_marche = 0
      this.form.situation_financiere_ordonnancement = 0
      this.situationMarcheDecompte({id_marche:id_marche,id_ordonnancement:id_ordonnancement})
    },
    // recreate(value, key) {
    //   var splinter = value.split(" ")
    //   this.form[key] = ""
    //   for (let index = 0; index < splinter.length; index++) {
    //     this.form[key] += splinter[index]
    //   }
    // },
    /**
     * Soumet le formulaire
     */
    submit() {
      if (this.type_user[1] == "AGEROUTE") {
        this.form.type_decompte = "decompte_intellectuel_ageroute"
      } else {
        this.form.type_decompte = "decompte_intellectuel_dgir"
      }
      // this.recreate(this.form.montant_ordonnancement, "montant_ordonnancement")
      // this.recreate(this.form.montant_total_contrat, "montant_total_contrat")
      // this.recreate(this.form.montant_facture_decompte, "montant_facture_decompte")
      if (Number(this.form.montant_facture_decompte) <= this.form.situation_financiere_marche) {
        if (
          !this.form
            .validate()
            .errors()
            .any()
        ) {
          this.saveDecomptes(this.form.data)
        } else {
          for (let affichekey in this.affiche) {
            const tabVale = this.affiche[affichekey].split(":")
            if (tabVale.length === 1) {
              this.affiche[affichekey] = tabVale[0] + ":true"
            }
          }
        }
      }else{
        this.notif.message = "Le montant de la facture/decompte ne doit pas excéder la situation financière du marché"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
      // if (
      //   !this.form
      //     .validate()
      //     .errors()
      //     .any()
      // ) {
      //   this.saveDecomptes(this.form.data)
      // } else {
      //   for (let afficheKey in this.affiche) {
      //     const tabVale = this.affiche[afficheKey].split(":")
      //     if (tabVale.length === 1) {
      //       this.affiche[afficheKey] = tabVale[0] + ":true"
      //     }
      //   }
      // }
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    // sauvegarde du décompte
    saving() {
      if (this.type_user[1] == "AGEROUTE") {
        this.form.type_decompte = "decompte_intellectuel_ageroute"
      } else {
        this.form.type_decompte = "decompte_intellectuel_dgir"
      }
      this.saveDP(this.form.data)
    },
    /**
     * Permet de faire un retour en arriere
     */
    goBack() {
      this.$router.push({ name: "decompte" })
    },
    /**
     * Apres l'affichage du message d'erreur,si le user click pour modifier le message d'erreur est effacer
     * @param element
     */
    removeSPan(element) {
      for (let afficheKey in this.affiche) {
        const valeur = this.affiche[afficheKey].split(":")[0]
        if (valeur === element) {
          this.affiche[afficheKey] = valeur
        }
      }
    },
    /**
     * Permet de verifier la periode donct date fin doit être superieur a la date debut
     */
    getDate() {
      utils.getDate(this, this.date_debut, this.date_fin, "intellectuelle")
    },
    /**
     * La fonction verifie les numero de decomptes deja utiliser et empeche d'en utiliser le même
     */
    verifierNumeroDecompte() {
      if (this.listNumeroDecompte.includes(this.form.numero_decompte)) {
        this.notif.message = "Ce numero de decompte est déjà utilisé. Veillez le changer!"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"

        setTimeout(
          function() {
            this.notif.activated = ""
            this.form.numero_decompte = ""
          }.bind(this),
          3000
        )
      }
    },
    /**
     * Creation des espaces pour les extentions de fichiers
     * @returns {string}
     * @constructor
     */
    CreateEspaceBetweenFileExtentions() {
      return utils.formaFichierAccepteToString()
    }
  }
}
</script>
